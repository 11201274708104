<template>
  <base-page-layout title="Usuários">
    <v-row no-gutters justify="end" class="mb-4">
      <v-col cols="auto">
        <mf-button color="primary" icon="add" label="Criar usuário" @click="openCreate"></mf-button>
      </v-col>
    </v-row>
    <mf-card-container
      v-model="search"
      placeholder="Busca (nome ou login)"
      use-search
      title="Listagem de usuários"
      :description="'Listagem de todos os usuários cadastrados na base'"
    >
      <v-row justify="end" no-gutters>
        <v-col class="mt-n2" cols="auto">
          <headers-data-table :headers.sync="headers" :default-headers="getDefaultHeadersOptions()" headers-settings-name="headers-list-users" />
        </v-col>
        <v-col cols="12">
          <v-data-table
            :loading="$apollo.queries.users.loading"
            :footer-props="{ itemsPerPageOptions: [5, 10, 25, 50] }"
            :options.sync="options"
            :headers="computedHeaders"
            :server-items-length="maxRecords"
            :items="data"
            :search="search"
          >
            <template v-slot:[`item.active`]="{ item }">
              <v-icon class="mr-2" x-small :color="item.active ? 'success' : 'error'">mdi-brightness-1</v-icon>{{ item.active ? 'Ativo' : 'Inativo' }}
            </template>
            <template v-slot:[`item.type_user`]="{ item }">
              <v-icon class="mr-2" x-small :color="typeUser(item.email) ? 'info' : 'warning'">mdi-brightness-1</v-icon
              >{{ typeUser(item.email) ? 'Interno' : 'Cliente' }}
            </template>
            <template v-slot:[`item.permissions.crm.multiUser`]="{ item }">
              <v-icon class="mr-2" x-small :color="multiUser(item) ? 'info' : 'warning'">mdi-brightness-1</v-icon>{{ multiUser(item) ? 'Sim' : 'Não' }}
            </template>
            <template v-slot:[`item.login`]="{ item }">
              {{ item.login.lenght ? item.login[0] : item.login }}
            </template>
            <template v-slot:[`item.deviceConfirmation`]="{ item }">
              <v-icon class="mr-2" x-small :color="item.deviceConfirmation ? 'info' : 'warning'">mdi-brightness-1</v-icon
              >{{ item.deviceConfirmation ? 'Sim' : 'Não' }}
            </template>
            <template v-slot:[`item.deviceConfirmation.code`]="{ item }">
              {{ item.deviceConfirmation ? item.deviceConfirmation.code : '-' }}
            </template>
            <template v-slot:[`item.deviceConfirmation.fingerprint`]="{ item }">
              {{ item.deviceConfirmation ? item.deviceConfirmation.fingerprint : '-' }}
            </template>
            <template v-slot:[`item.deviceConfirmation.origin`]="{ item }">
              {{ item.deviceConfirmation ? item.deviceConfirmation.origin : '-' }}
            </template>
            <template v-slot:[`item.emailConfirmed`]="{ item }">
              <v-icon class="mr-2" x-small :color="item.emailConfirmed ? 'info' : 'warning'">mdi-brightness-1</v-icon>{{ item.emailConfirmed ? 'Sim' : 'Não' }}
            </template>
            <template v-slot:[`item.options`]="{ item }">
              <v-menu bottom transition="slide-x-transition">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">more_vert</v-icon>
                </template>
                <v-list class="ma-0 pa-0">
                  <v-list-item link @click="openDetails(item)">
                    <v-list-item-title class="body-2">Detalhes</v-list-item-title>
                  </v-list-item>
                  <v-list-item link :disabled="disable(item)" @click="openDevices(item)">
                    <v-list-item-title class="body-2">Histórico Dispositivos</v-list-item-title>
                  </v-list-item>
                  <v-list-item link>
                    <v-list-item-title class="body-2" @click="toggleActive(item)">{{ item.active ? 'Inativar Usuário' : 'Ativar Usuário' }} </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </mf-card-container>
    <devices :dialog="devicesDialog" :active-fingerprint="activeFingerprint" :name-user="nameUser" :devices="devices" @close="closeDevices" />
  </base-page-layout>
</template>

<script>
import { QUERY_GET_INTERNAL_USERS, MUTATION_UPDATE_ACTIVE_USER } from '@/modules/users/graphql'
import { cloneDeep } from 'lodash'

const defaultHeaders = [
  {
    text: 'Login',
    value: 'email',
    width: '10%',
    isActive: true,
    isRequired: true
  },
  {
    text: 'Nome',
    value: 'name',
    width: '10%',
    isActive: true,
    isRequired: true
  },
  {
    text: 'Usuário',
    value: 'type_user',
    width: '10%',
    isActive: true,
    isRequired: true
  },
  {
    text: 'Status',
    value: 'active',
    width: '10%',
    isActive: true
  },
  {
    text: 'Multiuser',
    value: 'permissions.crm.multiUser',
    width: '10%',
    isActive: true
  },
  {
    text: 'Confirmação Dispositivo',
    value: 'deviceConfirmation',
    width: '10%',
    isActive: true
  },
  {
    text: 'Código do Dispositivo',
    value: 'deviceConfirmation.code',
    width: '10%',
    isActive: false
  },
  {
    text: 'Fingerprint',
    value: 'deviceConfirmation.fingerprint',
    width: '10%',
    isActive: false
  },
  {
    text: 'Origem',
    value: 'deviceConfirmation.origin',
    width: '10%',
    isActive: false
  },
  {
    text: 'Email Confirmado',
    value: 'emailConfirmed',
    width: '10%',
    isActive: false
  },
  {
    text: '',
    value: 'options',
    sortable: false,
    width: '10%',
    isActive: true,
    isRequired: true
  }
]
export default {
  components: {
    BasePageLayout: () => import('@/components/base/BasePageLayout'),
    Devices: () => import('@/modules/users/components/dialogs/Devices'),
    HeadersDataTable: () => import('../../../components/atomic-components/molecules/HeadersDataTable')
  },
  data: () => ({
    search: '',
    dialog: false,
    detailsDialog: false,
    devicesDialog: false,
    userId: null,
    refresh: false,
    devices: [],
    nameUser: '',
    headers: [],
    activeFingerprint: '',
    options: {},
    data: [],
    maxRecords: 0
  }),
  computed: {
    query() {
      return QUERY_GET_INTERNAL_USERS
    },
    computedHeaders() {
      const sizeWithoutCodeAndProduct = 100 - (24 + 8.5)
      const headers = this.headers.filter(header => header.isActive)
      const activableHeadersSize = headers.filter(header => !header.isRequired).length
      const result = headers.map(header => {
        return {
          ...header,
          width: header.width ? header.width : `${sizeWithoutCodeAndProduct / activableHeadersSize}%`
        }
      })
      return result
    }
  },
  apollo: {
    users: {
      query: QUERY_GET_INTERNAL_USERS,
      fetchPolicy: 'network-only',
      variables() {
        return Object.assign(
          {},
          { page: this.options.page || 1 },
          { pageSize: this.options.itemsPerPage || 10 },
          { sort: this.options.sortBy },
          { sortDirection: this.options.sortDesc && this.options.sortDesc.map(direction => (direction ? 'DESC' : 'ASC')) },
          this.search ? { search: this.search } : null
        )
      },
      update({ authUsers: { count, data } }) {
        this.maxRecords = count
        this.data = data
      }
    }
  },
  watch: {
    search(val) {
      this.options.page = 1
    }
  },
  methods: {
    multiUser(item) {
      return item?.permissions?.crm?.multiUser
    },
    disable(item) {
      return !(item.devices !== null && item?.devices?.length > 0)
    },
    getDefaultHeadersOptions() {
      return cloneDeep(defaultHeaders)
    },
    async toggleActive(user) {
      const confirmation = await this.$confirm({
        title: `${user.active ? 'Inativar' : 'Ativar'} Usuário`,
        message: `Deseja ${user.active ? 'inativar' : 'ativar'} o usuário?`,
        confirmText: 'Continuar',
        cancelText: 'Cancelar'
      })
        .then(() => true)
        .catch(() => false)
      if (!confirmation) return
      try {
        await this.$apollo
          .mutate({
            mutation: MUTATION_UPDATE_ACTIVE_USER,
            variables: {
              login: user.email
            }
          })
          .then(() => {
            this.$apollo.queries.users.refetch()
            this.$alert({
              alert_message: `Usuário ${user.active ? 'inativado' : 'ativado'} com sucesso.`,
              alert_title: 'Sucesso!',
              alert_color: 'success',
              alert_icon: 'mdi-check-circle'
            })
          })
      } catch (err) {
        console.log(err)
        this.$alert({
          alert_message: 'Falha ao realizar ação!',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      }
    },
    openDetails(user) {
      this.$router.push(`/usuarios/${user._id}/detalhes/dados-gerais`)
    },
    openCreate() {
      this.$router.push(`/usuarios/cadastro`)
    },
    openDevices(user) {
      this.devicesDialog = true
      this.devices = user.devices
      this.nameUser = user.name
      this.activeFingerprint = user.deviceConfirmation?.fingerprint
    },
    closeDevices() {
      this.devicesDialog = false
      this.devices = []
      this.nameUser = ''
    },
    typeUser(email) {
      return email.split('@')[1] === 'mercafacil.com'
    }
  }
}
</script>
