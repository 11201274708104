var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-page-layout',{attrs:{"title":"Usuários"}},[_c('v-row',{staticClass:"mb-4",attrs:{"no-gutters":"","justify":"end"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('mf-button',{attrs:{"color":"primary","icon":"add","label":"Criar usuário"},on:{"click":_vm.openCreate}})],1)],1),_c('mf-card-container',{attrs:{"placeholder":"Busca (nome ou login)","use-search":"","title":"Listagem de usuários","description":'Listagem de todos os usuários cadastrados na base'},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},[_c('v-row',{attrs:{"justify":"end","no-gutters":""}},[_c('v-col',{staticClass:"mt-n2",attrs:{"cols":"auto"}},[_c('headers-data-table',{attrs:{"headers":_vm.headers,"default-headers":_vm.getDefaultHeadersOptions(),"headers-settings-name":"headers-list-users"},on:{"update:headers":function($event){_vm.headers=$event}}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"loading":_vm.$apollo.queries.users.loading,"footer-props":{ itemsPerPageOptions: [5, 10, 25, 50] },"options":_vm.options,"headers":_vm.computedHeaders,"server-items-length":_vm.maxRecords,"items":_vm.data,"search":_vm.search},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"x-small":"","color":item.active ? 'success' : 'error'}},[_vm._v("mdi-brightness-1")]),_vm._v(_vm._s(item.active ? 'Ativo' : 'Inativo')+" ")]}},{key:"item.type_user",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"x-small":"","color":_vm.typeUser(item.email) ? 'info' : 'warning'}},[_vm._v("mdi-brightness-1")]),_vm._v(_vm._s(_vm.typeUser(item.email) ? 'Interno' : 'Cliente')+" ")]}},{key:"item.permissions.crm.multiUser",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"x-small":"","color":_vm.multiUser(item) ? 'info' : 'warning'}},[_vm._v("mdi-brightness-1")]),_vm._v(_vm._s(_vm.multiUser(item) ? 'Sim' : 'Não')+" ")]}},{key:"item.login",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.login.lenght ? item.login[0] : item.login)+" ")]}},{key:"item.deviceConfirmation",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"x-small":"","color":item.deviceConfirmation ? 'info' : 'warning'}},[_vm._v("mdi-brightness-1")]),_vm._v(_vm._s(item.deviceConfirmation ? 'Sim' : 'Não')+" ")]}},{key:"item.deviceConfirmation.code",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.deviceConfirmation ? item.deviceConfirmation.code : '-')+" ")]}},{key:"item.deviceConfirmation.fingerprint",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.deviceConfirmation ? item.deviceConfirmation.fingerprint : '-')+" ")]}},{key:"item.deviceConfirmation.origin",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.deviceConfirmation ? item.deviceConfirmation.origin : '-')+" ")]}},{key:"item.emailConfirmed",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"x-small":"","color":item.emailConfirmed ? 'info' : 'warning'}},[_vm._v("mdi-brightness-1")]),_vm._v(_vm._s(item.emailConfirmed ? 'Sim' : 'Não')+" ")]}},{key:"item.options",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","transition":"slide-x-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("more_vert")])]}}],null,true)},[_c('v-list',{staticClass:"ma-0 pa-0"},[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.openDetails(item)}}},[_c('v-list-item-title',{staticClass:"body-2"},[_vm._v("Detalhes")])],1),_c('v-list-item',{attrs:{"link":"","disabled":_vm.disable(item)},on:{"click":function($event){return _vm.openDevices(item)}}},[_c('v-list-item-title',{staticClass:"body-2"},[_vm._v("Histórico Dispositivos")])],1),_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',{staticClass:"body-2",on:{"click":function($event){return _vm.toggleActive(item)}}},[_vm._v(_vm._s(item.active ? 'Inativar Usuário' : 'Ativar Usuário')+" ")])],1)],1)],1)]}}],null,true)})],1)],1)],1),_c('devices',{attrs:{"dialog":_vm.devicesDialog,"active-fingerprint":_vm.activeFingerprint,"name-user":_vm.nameUser,"devices":_vm.devices},on:{"close":_vm.closeDevices}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }